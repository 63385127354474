<template>
  <div class="col-12 col-lg-8 c g">
    <div class="card card-body">
        <h1>
            #1
            قم بتثبيت الاداة <a target="_blank" href="https://chromewebstore.google.com/detail/ladgmenhepkfonbblbmepcccimhfcjmk?hl=ar" class="btn btn-primary">
            <i class="fa fa-external-link"></i>
            اضغط هنا
            </a>
        </h1>
        <p>افتح الرابط واضغط على "الاضافة إلى Chrome"</p>
        <center>
            <a target="_blank" href="https://chromewebstore.google.com/detail/ladgmenhepkfonbblbmepcccimhfcjmk?hl=ar"><img :src="require('@/assets/images/extension.png')" class="c" style="width: 80%; border: solid 2px #ddd"></a>
        </center>
    </div>
    <div class="card card-body">
        <h1>
            #2
            شاهد شرح ربط الاداة بحسابك
            <a href="https://youtu.be/R8WMRaqMFBo" target="_blank" class="btn btn-danger">
            <i class="fa fa-youtube"></i>
            من هنا
            </a>
        </h1>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>